<template>
  <!--begin::Calendar-->
  <div class="calendar-template" v-if="getPermission('calendar:view')">
    <DetailTemplate customSecondClass="pt-0">
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <div v-if="eventLoading" class="block-ui">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="8"
              color="cyan"
            ></v-progress-circular>
          </div>
          <v-row class="fill-height">
            <v-col md="12" class="py-0">
              <v-sheet height="64">
                <v-toolbar flat class="px-0">
                  <div class="w-100 d-flex">
                    <div class="mr-3">
                      <v-btn
                        outlined
                        class="mr-4"
                        :disabled="isToday()"
                        color="grey darken-2"
                        @click="setToday()"
                      >
                        Today
                      </v-btn>
                    </div>
                    <div class="ml-3 w-100 d-flex align-center">
                      <div v-if="false" class="mx-3 w-100 text-right">
                        <v-btn
                          fab
                          text
                          small
                          color="grey darken-2"
                          @click="prev"
                        >
                          <v-icon small> mdi-chevron-left </v-icon>
                        </v-btn>
                      </div>
                      <div class="mx-3 w-100 text-center">
                        <v-toolbar-title
                          v-if="$refs.calendar"
                          class="font-weight-700"
                        >
                          {{ $refs.calendar.title }}
                        </v-toolbar-title>
                      </div>
                      <div v-if="false" class="mx-3 w-100 text-left">
                        <v-btn
                          fab
                          text
                          small
                          color="grey darken-2"
                          @click="next"
                        >
                          <v-icon small> mdi-chevron-right </v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div class="ml-3">
                      <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            color="grey darken-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right> mdi-menu-down </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <div class="ml-3 justify-end">
                      <Datepicker
                        solo
                        flat
                        :default-date="defaultDate"
                        v-on:update:date-picker="setDefaultDate($event)"
                      ></Datepicker>
                    </div>
                    <div class="ml-3 justify-end d-flex">
                      <div class="w-100 justify-end">
                        <v-btn
                          class="custom-bold-button w-100"
                          depressed
                          color="cyan white--text"
                          v-on:click="$router.push(getDefaultRoute('calendar'))"
                        >
                          Calendar
                        </v-btn>
                      </div>
                      <div class="w-100 justify-end">
                        <v-btn
                          class="custom-bold-button w-100"
                          depressed
                          v-on:click="
                            $router.push(getDefaultRoute('calendar.resource-h'))
                          "
                        >
                          Resource
                        </v-btn>
                      </div>
                      <div v-if="false" class="w-100 justify-end">
                        <v-btn
                          class="custom-bold-button w-100"
                          depressed
                          v-on:click="
                            $router.push(getDefaultRoute('calendar.map'))
                          "
                        >
                          Map
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="calc(100vh - 140px)">
                <v-calendar
                  ref="calendar"
                  :start="defaultDate"
                  v-model="focus"
                  color=""
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:more="showEventPopup($event)"
                  @click:date="viewDay"
                >
                  <template v-slot:event="{ event }">
                    <div
                      :class="`d-flex align-center h-100 ${getStatusColor(
                        event.status_text
                      )} lighten-4`"
                      :style="`border:2px solid ${getStatusBirderColor(
                        event.status_text
                      )} !important`"
                    >
                      <span class="m-0">
                        <v-icon
                          class="m-0"
                          :content="event.status_text"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          small
                          :color="getStatusColor(event.status_text)"
                          >mdi-record</v-icon
                        >
                      </span>
                      <span class="m-0 mx-2 black--text">{{ event.name }}</span>
                      <!-- <span class="m-0">
                        <v-icon
                          class="m-0"
                          :content="event.engineer_display_name"
                          :color="event.engineer_color"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          small
                          >mdi-record</v-icon
                        >
                      </span>   -->
                    </div>
                  </template>
                  <template v-slot:day-label="{ day, date }">
                    <div
                      class="py-2 cursor-default"
                      v-on:drop="dropEngineer(date)"
                      v-on:dragover="allowDrop"
                    >
                      <span>{{ day }}</span>
                    </div>
                  </template>
                </v-calendar>

                <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-y
                >
                  <v-card
                    min-width="245px"
                    flat
                    class="pa-1"
                    color="grey lighten-4"
                  >
                    <template v-for="data in eventspopup">
                      <template v-for="(datavisit, index) in data.all_data">
                        <v-card-text
                          :key="index"
                          :class="`${getStatusColor(
                            datavisit.status_text
                          )} lighten-4 mb-1 white--text`"
                          :style="`border:2px solid ${getStatusBirderColor(
                            datavisit.status_text
                          )} !important`"
                        >
                          <span class="m-0">
                            <v-icon
                              class="m-0"
                              :content="datavisit.status_text"
                              v-tippy="{
                                arrow: true,
                                arrowType: 'round',
                                animation: 'fade',
                              }"
                              small
                              :color="getStatusColor(datavisit.status_text)"
                              >mdi-record</v-icon
                            >
                          </span>
                          <span :class="`m-0 mx-2 black--text`">{{
                            datavisit.name
                          }}</span>
                        </v-card-text>
                      </template>
                    </template>
                  </v-card>
                </v-menu>
              </v-sheet>
            </v-col>
            <v-col v-if="false" md="3">
              <template v-if="jobLoading">
                <h3
                  class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                  style="line-height: 64px !important"
                >
                  <v-skeleton-loader
                    class="my-5"
                    type="heading"
                  ></v-skeleton-loader>
                </h3>
                <v-simple-table
                  class="table-bordered"
                  fixed-header
                  dense
                  height="calc(100vh - 200px)"
                >
                  <template v-slot:default>
                    <thead>
                      <tr v-for="i in 10" :key="i">
                        <td class="simple-table-th font-size-16" width="100">
                          <v-skeleton-loader
                            class="custom-skeleton py-2 my-0"
                            type="text"
                          ></v-skeleton-loader>
                        </td>
                        <td class="simple-table-th font-size-16">
                          <v-skeleton-loader
                            class="py-2 my-0"
                            type="sentences"
                          ></v-skeleton-loader>
                        </td>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>
              </template>
              <template v-else>
                <template v-if="unAssignedJobs.length > 0">
                  <h3
                    class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                    style="line-height: 64px !important"
                  >
                    ({{ unAssignedJobs.length }}) Unschedule Jobs
                  </h3>
                  <v-simple-table
                    class="table-bordered"
                    fixed-header
                    dense
                    height="calc(100vh - 200px)"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <td class="simple-table-th font-size-16" width="100">
                            Job #
                          </td>
                          <td class="simple-table-th font-size-16">Title</td>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(job, index) in unAssignedJobs">
                          <tr
                            :key="index"
                            draggable="true"
                            v-on:dragstart="dragStart(job)"
                          >
                            <td class="simple-table-td" width="100">
                              <Barcode :barcode="job.barcode"></Barcode>
                            </td>
                            <td class="simple-table-td">{{ job.title }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <template v-else>
                  <h3
                    class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                    style="line-height: 64px !important"
                  >
                    No Unschedule Jobs
                  </h3>
                </template>
              </template>
            </v-col>
          </v-row>
          <template v-if="getPermission('visit:view')">
            <SingleVisitDetail
              v-if="viewVisitDialog"
              :visit-dialog="viewVisitDialog"
              :visit="visitId"
              v-on:close="viewVisitDialog = false"
            ></SingleVisitDetail>
          </template>
          <template v-if="getPermission('visit:create')">
            <AddVisitDialog
              v-if="addVisitDialog"
              :detail="ticketDetail"
              :customer="ticketCustomer"
              :property="ticketProperty"
              :contactPerson="ticketContactPerson"
            ></AddVisitDialog>
          </template>
        </v-container>
      </template>
    </DetailTemplate>
  </div>
  <!--end::Calendar-->
</template>

<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import { GET, QUERY } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import AddVisitDialog from "@/view/pages/job/partials/Add-Visit-Dialog.vue";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";
//import { map } from "lodash";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "calendar",
  components: {
    DetailTemplate,
    Barcode,
    Datepicker,
    SingleVisitDetail,
    AddVisitDialog,
  },
  data: () => ({
    defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
    eventLoading: true,
    jobLoading: true,
    viewVisitDialog: false,
    addVisitDialog: false,
    visitId: 0,
    visitTicketDetail: new Object(),
    visitTicketCustomer: new Object(),
    visitTicketProperty: new Object(),
    visitTicketContactPerson: new Object(),
    ticketDetail: new Object(),
    ticketCustomer: new Object(),
    ticketProperty: new Object(),
    ticketContactPerson: new Object(),
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    events: [],
    selectedOpen: false,
    selectedElement: null,
    selectedEvent: {},
    unAssignedJobs: [],
    eventspopup: null,
  }),
  mounted() {
    const _this = this;
    _this.getCalendarEvents();
    _this.getUnassignedVisits();
    if (_this.$refs["calendar"]) {
      _this.$refs["calendar"].checkChange();
    }
    _this.$store.dispatch(SET_BREADCRUMB, [{ title: "Calendar" }]);
    DialogJobEventBus.$on("load:visit", () => {
      _this.getCalendarEvents();
      _this.getUnassignedVisits();
    });
    DialogJobEventBus.$on("load:job", () => {
      _this.visitId = 0;
      _this.viewVisitDialog = false;
      _this.visitTicketDetail = new Object();
      _this.visitTicketCustomer = new Object();
      _this.visitTicketProperty = new Object();
      _this.visitTicketContactPerson = new Object();
    });
    DialogJobEventBus.$on("close:add-visit-dialog", () => {
      _this.addVisitDialog = false;
    });
  },

  beforeDestroy() {
    /*DialogJobEventBus.$off("close:add-visit-dialog");
    DialogJobEventBus.$off("load:visit");
    DialogJobEventBus.$off("load:job");*/
  },
  methods: {
    showEventPopup({ nativeEvent }) {
      //console.log(nativeEvent.target);
      this.selectedElement = nativeEvent.target;
      const date = this.selectedElement.getAttribute("data-date");
      // console.log(date);
      const open = () => {
        // this.selectedEvent = event

        // this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
      this.getPopupVisit(date);
    },

    getPopupVisit(date) {
      const _this = this;

      _this.$store
        .dispatch(QUERY, {
          url: "popup-outlookcalendar",
          data: { start_date: date },
        })
        .then((data) => {
          _this.eventspopup = data;
        });
    },

    getStatusColor(status) {
      if (status == "Open") {
        return "blue";
      }
      if (status == "In-Progress") {
        return "yellow";
      }
      if (status == "Hold") {
        return "orange";
      }
      if (status == "Completed") {
        return "green";
      }
      if (status == "Cancelled") {
        return "red";
      }
      if (status == "Send") {
        return "orange";
      }
      return "cyan";
    },
    getStatusBirderColor(status) {
      if (status == "Open") {
        return "#69b4f1";
      }
      if (status == "In-Progress") {
        return "yellow";
      }
      if (status == "Hold") {
        return "orange";
      }
      if (status == "Completed") {
        return "#21b721";
      }
      if (status == "Cancelled") {
        return "#ff00007d";
      }
      if (status == "Send") {
        return "orange";
      }
      return "cyan";
    },

    getPriorityColor(priority_text) {
      if (priority_text == "Low") {
        return "light-green darken-3";
      }
      if (priority_text == "Medium") {
        return "orange darken-4";
      }
      if (priority_text == "High") {
        return "red darken-3";
      }
      return "cyan";
    },
    setDefaultDate(date) {
      this.defaultDate = moment(date).startOf("day").format("YYYY-MM-DD");
      if (this.$refs["calendar"]) {
        this.$refs["calendar"].checkChange();
      }
    },
    setToday() {
      this.focus = "";
      this.defaultDate = moment().startOf("day").format("YYYY-MM-DD");
      if (this.$refs["calendar"]) {
        this.$refs["calendar"].checkChange();
      }
    },
    isToday() {
      let defaultDate = moment(this.defaultDate)
        .startOf("day")
        .format("YYYY-MM-DD");
      if (this.$refs["calendar"]) {
        if (this.$refs["calendar"].value) {
          defaultDate = moment(this.$refs["calendar"].value)
            .startOf("day")
            .format("YYYY-MM-DD");
        }
      }
      const today = moment().startOf("day").format("YYYY-MM-DD");
      return today == defaultDate;
    },
    getCalendarEvents() {
      const _this = this;
      _this.eventLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "calendar/outlookmeeting",
        })
        .then(({ data }) => {
          //  console.log(data,"hello");
          _this.events = data;

          // console.log( _this.events,"hello");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.eventLoading = false;
        });
    },
    getUnassignedVisits() {
      const _this = this;
      _this.jobLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "job/un-assigned",
        })
        .then(({ data }) => {
          _this.unAssignedJobs = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobLoading = false;
        });
    },
    dragStart(row) {
      this.ticketDetail = new Object({
        barcode: row.barcode,
        attention: row.attention,
        title: row.title,
        id: row.ticket_id,
      });
      this.ticketCustomer = new Object({
        customer: row.customer,
      });
      this.ticketProperty = new Object({
        unit_no: row.unit_no,
        street_1: row.street_1,
        street_2: row.street_2,
        zip_code: row.zip_code,
      });
      this.ticketContactPerson = new Object({
        primary_phone: row.customer_primary_phone,
      });
    },
    dropEngineer(scheduleDate) {
      this.addVisitDialog = true;
      setTimeout(function () {
        JobScheduleTeamEventBus.$emit("update:schedule-date", scheduleDate);
      }, 400);
    },
    allowDrop(event) {
      event.preventDefault();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    clearAll() {
      this.visitId = null;
      this.viewVisitDialog = false;
      this.visitTicketDetail = new Object();
      this.visitTicketCustomer = new Object();
      this.visitTicketProperty = new Object();
      this.visitTicketContactPerson = new Object();
    },
    /* showEvent({ nativeEvent, event }) {
      if (!event.visit) {
        return false;
      }

      this.visitId = this.lodash.toSafeInteger(event.id);

      this.viewVisitDialog = true;

      nativeEvent.stopPropagation();

      _this.eventLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "visit/" + visit,
        })
        .then(({ data }) => {
          const {ticket, title, barcode, id, customer, property, property_contact_person} = data;
          _this.visitId = id;
          _this.visitTicketDetail = new Object({
            barcode: barcode,
            attention: ticket.attention,
            title: title,
            ticket_type: ticket.ticket_type,
            id: ticket.id,
          });
          _this.visitTicketCustomer = new Object({customer});
          _this.visitTicketProperty = new Object({
            unit_no: property.unit_no,
            street_1: property.street_1,
            street_2: property.street_2,
            zip_code: property.zip_code,
            latitude: property.latitude,
            longitude: property.longitude,
          });
          _this.visitTicketContactPerson = new Object({
            primary_phone: property_contact_person.primary_phone,
          });
          _this.$nextTick(() => {
            _this.viewVisitDialog = true;
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.eventLoading = false;
        });
    },*/
  },
};
</script>
